import React from 'react'
import HeroSection from '../components/Hero/HeroSection'
import ListSlider from '../components/list-slider/ListSlider'
import Features from '../components/Features/Features'
import PopularModels from '../components/product-list/ProductList'
import Collections from '../components/collections/Collections'
import Blog from "../components/blog/Blog"
import ProductList from '../components/product-list/ProductList'
import Showoff from '../components/Showoff/Showoff'
import NewsLetter from '../components/newsletter/NewsLetter'
import Footer from '../components/footer/Footer'
import AppHeader from '../components/navbar/AppHeader'
import ShippingFeatures from '../components/ShippingFeatures/ShippingFeatures'

const Home = () => {
  return (
    <div>
        <AppHeader />

        <HeroSection />

        <ShippingFeatures />

        <ProductList sectionName='Featured Products' />
        
        <Collections />


        <Features />

        {/* <ListSlider /> */}



        <Blog />

        <ProductList sectionName='Recommended' />

        <Showoff />

        <ProductList sectionName='New Collections' />

        <NewsLetter />

        <Footer />
    </div>
  )
}

export default Home