import React from 'react'
import "./Showoff.scss"

const Showoff = () => {
  return (
    <section className='container-fluid p-0'>
        <img src="https://png.pngtree.com/template/20220330/ourlarge/pngtree-black-gold-style-simple-high-end-watch-banner-image_908768.jpg" 
            className='img-fluid w-100'
        />
    </section>
  )
}

export default Showoff