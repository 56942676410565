import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { apiPostWithoutAuth } from "../util/ApiRequest";
import { ENDPOINTS } from "../util/EndPoint";
import { isLoaderState, snakeBarState } from "../util/RecoilStore";
import { useRecoilState } from "recoil";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import '../scss/Login.scss'
import AppHeader from "../components/navbar/AppHeader";
import Footer from "../components/footer/Footer";
import LoginImage from "../assets/images/bg-logon.png";
import { Link } from "react-router-dom";

const Login = () => {

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const emailEl = React.useRef(null);
  const passwordEl = React.useRef(null);
  
  const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (event) => {
    setIsLoaderInfo(true);

    event.preventDefault();
    const body = {
      email: emailEl.current.value,
      password: passwordEl.current.value,
      device_token: "testing",
    };
    apiPostWithoutAuth(
      ENDPOINTS.UserLogin,
      body,
      (res) => {
        const Roles = res.data?.user_details?.role;
        localStorage.setItem("accessToken", res?.data?.token);
        localStorage.setItem("user", JSON.stringify(res?.data?.user_details));
        console.log(res);

        if (Roles === "admin") {
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-success",
            snackMsg: "Successful",
          });
          window.location.href = `/admin/dashboard`;
        }
        if (Roles !== "admin") {
          setIsLoaderInfo(false);
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-primary",
            snackMsg: "admin is not valid",
          });
        }
      },
      (error) => {
        setIsLoaderInfo(false);

        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-primary",
          snackMsg: "There is an Error Plz Try Again",
        });
      }
    );
  };

  return (
    <>
      <AppHeader />
      <img 
        src={LoginImage}
        className="img-fluid w-100 login-banner" 
      />
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center py-4">
        <form className="d-flex flex-column gap-5 login-form-container p-5">
          <h1>Login</h1>
          <div className="position-relative">
            <label htmlFor="InputEmail1" className="form-label login-input-label">Email address</label>
            <input type="email" className="form-control login-input" id="InputEmail1" aria-describedby="emailHelp" />          
          </div>
          <div className="position-relative">
            <label htmlFor="InputPassword1" className="form-label login-input-label">Password</label>
            <input type={passwordVisible ? "text" : "password"} className="form-control login-input" id="InputPassword1" />
            <button
                className="btn show-hide-btn"
                type="button"
                onClick={togglePasswordVisibility}
                >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <Button className="btn btn-primary text-white py-3 fs-4 fw-bold" children="Login" />
        </form>
        <Link to={'/signup'} className='text-primary'>
            Create Account
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default Login;
