import React from 'react'
import "./Features.scss"

const Features = () => {

    const featuresData = [
        {
            img: "https://static.chrono24.com/images/default/illustrations/buyer-rating.svg",
            title: "4.8 out of 5 stars",
            description: "from 161,000 reviews worldwide"
        },
        {
            img: "https://static.chrono24.com/images/default/illustrations/love-my-watch.svg",
            title: "9 million",
            description: "watch enthusiasts use Chrono24 each month"
        },
        {
            img: "https://static.chrono24.com/images/default/illustrations/handshake.svg",
            title: "over 200,000",
            description: "customers choose Buyer Protection annually"
        },
        {
            img: "https://static.chrono24.com/images/default/illustrations/dealer.svg",
            title: "More than 25,000",
            description: "trustworthy sellers"
        },
    ]

  

  return (
    <section className='container-fluid py-5 feature-list-main bg-lightgray'>
        <div className='container'>
                <div className='row'>
                {featuresData.map((feature, index) => (
                    <div key={index} className='col-lg-3 col-md-6 text-center p-3 feature-card'>
                        <img src={feature.img} alt="" />
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                    </div>
                ))}
                </div>
        </div>
    </section>
  )
}

export default Features