import React from 'react'
import AppHeader from '../components/navbar/AppHeader'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useState } from 'react'
import "../scss/Signup.scss"
import Footer from '../components/footer/Footer'
import Button from '../components/button/ButtonCustom'
import { Link } from 'react-router-dom'
import LoginImage from "../assets/images/bg-logon.png";

const Signup = () => {

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

  return (
    <>
     <AppHeader />
      <img 
        src={LoginImage}
        className="img-fluid w-100 login-banner" 
      />
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center py-4">
            <form className="d-flex flex-column gap-5 signup-form-container p-5">
                <h1>Create Account</h1>
                <div className="position-relative mb-3">
                    <label htmlFor="InputFirstName" className="form-label signup-input-label">First Name</label>
                    <input type="text" className="form-control signup-input" id="InputFirstName" />
                </div>

                <div className="position-relative mb-3">
                    <label htmlFor="InputLastName" className="form-label signup-input-label">Last Name</label>
                    <input type="text" className="form-control signup-input" id="InputLastName" />
                </div>

                <div className="position-relative mb-3">
                    <label htmlFor="InputEmail1" className="form-label signup-input-label">Email address</label>
                    <input type="email" className="form-control signup-input" id="InputEmail1" aria-describedby="emailHelp" />
                </div>

                <div className="position-relative mb-3">
                    <label htmlFor="InputPassword" className="form-label signup-input-label">Password</label>
                    <input
                        type={passwordVisible ? 'text' : 'password'}
                        className="form-control signup-input"
                        id="InputPassword"
                    />
                    <button
                        className="btn show-hide-btn"
                        type="button"
                        onClick={togglePasswordVisibility}
                        >
                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                </div>

                <div className="position-relative mb-3">
                    <label htmlFor="InputPhoneNumber" className="form-label signup-input-label">Phone Number</label>
                    <input type="tel" className="form-control signup-input" id="InputPhoneNumber" />
                </div>

                <div class="form-check">
                    <input class="form-check-input signup-checkbox" type="checkbox" value="" id="flexCheckDefault" />
                    <label class="form-check-label" for="flexCheckDefault">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi quaerat ad dolorem expedita error aliquid.
                    </label>
                </div>
                <Button className="btn btn-primary text-white py-3 fs-4 fw-bold" children="Signup" />
            </form>
            <Link to={'/login'} className='text-primary'>
                Login to your Account
            </Link>
        </div>
        <Footer />
    </>
  )
}

export default Signup