import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ListSlider.scss";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Button from '../button/ButtonCustom';

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 10, // Default number of slides to show
    slidesToScroll: 5, // Default number of slides to scroll
    nextArrow: <IoIosArrowForward color='black' />,
    prevArrow: <IoIosArrowBack color='black' />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 1024, // Large screens
        settings: {
          slidesToShow: 4, // Show 3 slides
          slidesToScroll: 4, // Scroll 3 slides
        }
      },
      {
        breakpoint: 768, // Medium screens
        settings: {
          slidesToShow: 3, // Show 2 slides
          slidesToScroll: 3, // Scroll 2 slides
        }
      },
      {
        breakpoint: 576, // Small screens
        settings: {
          slidesToShow: 3, // Show 1 slide
          slidesToScroll: 3, // Scroll 1 slide
        }
      }
    ]
  };

  // const slides = [
  //   { id: 1, brand1: 'Rolex', brand2: 'Seiko' },
  //   { id: 2, brand1: 'Omega', brand2: 'Casio' },
  //   { id: 3, brand1: 'Breitling', brand2: 'Citizen' },
  //   { id: 4, brand1: 'Tag Heuer', brand2: 'Fossil' },
  //   { id: 5, brand1: 'Tissot', brand2: 'Swatch' },
  //   { id: 6, brand1: 'Longines', brand2: 'Hamilton' },
  // ];

  const slides = [
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
    { name: "Rolex", image: "https://www.bobswatches.com/images/brand-slider/ribbon/ribbon-rolex.jpg" },
  ]

  return (

    <section className='container list-slider-container py-5 d-flex flex-column'>
        <h2 className='mb-4'>Popular Brands</h2>
        <Slider {...settings}>
            {slides.map((slide, index) => (
                <div key={index} className='slick-slide-custom'>
                    <div className='d-flex align-items-center gap-3 p-2 border rounded-5'>
                      <img src={slide.image} className='slider-brand-images'/>
                      <p className='text-black'>{slide.name}</p>
                    </div>
                </div>
            ))}
        </Slider>

        <Button className='border-black align-self-center mt-5 slider-btn' children='Display All' />
        
    </section>
  );
};

export default Carousel;
