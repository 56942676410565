import React from 'react'

const ButtonCustom = ({ children, className, onClick, type, disabled, ...rest }) => {
    return (
      <button
        type={type}
        className={`btn ${className}`} // Combine Bootstrap classes with custom classes
        onClick={onClick}
        {...rest}
      >
        {children}
      </button>
    );
  };

export default ButtonCustom