import React from 'react'
import "./Collections.scss"
import ButtonCustom from "../button/ButtonCustom"

const Collections = () => {

    // const myCollections = [
    //     { name: "Mens Collection", description: "Stylish and Elegent" }
    // ]

  return (
    <section className='container-fluid'>
        <div className="row">
            <div className='py-3 col-12 col-lg-4 collections-item'>
                <div className='d-flex flex-column gap-5 align-items-center justify-content-center collections-item-content'>
                    <h2 className='text-white'>Mens Collection</h2>
                    <h4 className='text-white'>Stylish and Elegent</h4>
                    <ButtonCustom className='btn btn-outline-white' children="View More" />
                </div>
            </div>
            <div className='py-3 col-12 col-lg-4 collections-item'>
                <div className='d-flex flex-column gap-5 align-items-center justify-content-center collections-item-content'>
                    <h2 className='text-white'>Women Collection</h2>
                    <h4 className='text-white'>Stylish and Elegent</h4>
                    <ButtonCustom className='btn btn-outline-white' children="View More" />
                </div>
            </div>
            <div className='py-3 col-12 col-lg-4 collections-item'>
                <div className='d-flex flex-column gap-5 align-items-center justify-content-center collections-item-content'>
                    <h2 className='text-white'>Wall Clock Collection</h2>
                    <h4 className='text-white'>Stylish and Elegent</h4>
                    <ButtonCustom className='btn btn-outline-white' children="View More" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Collections