import React from 'react'
import Navbar from '../components/navbar/AppHeader'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageSlider from '../components/image-slider/ImageSlider';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Button from '../components/button/ButtonCustom';
import ProductList from '../components/product-list/ProductList';
import Footer from '../components/footer/Footer';

const images = [
    "https://icasiostore.pk/wp-content/uploads/2024/04/A168WEHA-9A_front.webp",
    "https://icasiostore.pk/wp-content/uploads/2024/04/A168WEHA-9A.webp",
    "https://icasiostore.pk/wp-content/uploads/2024/04/A168WEHA-9A_front.webp",
    "https://icasiostore.pk/wp-content/uploads/2022/04/47_self_adjustable-1.webp"
]

const specifications = {
    Brand: "Rolex",
    Model: "Oyster Perpetual",
    Movement: "Automatic",
    CaseDiameter: "36mm",
    WaterResistance: "100m",
    CaseMaterial: "Stainless Steel",
    StrapMaterial: "Oystersteel",
    PowerReserve: "48 hours",
};

const ProductPage = () => {


    return (
        <div className='product-page'>
            <Navbar />
            <div className='container py-5'>

                <div className='row mb-5'>
                    <div className="col-12 col-lg-6">
                        <ImageSlider images={images} />
                    </div>


                    <div className="col-12 col-lg-6 d-flex flex-column gap-5">
                        <div >
                            <h1>Watch Name</h1>
                            <h5 className='text-black'>Watch Price: Rs 25,000</h5>
                        </div>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Neque, fuga fugiat? Aperiam debitis quasi, totam suscipit ipsum a cum repellendus magni! Tempore ex dicta laborum quos ea nulla eveniet et incidunt quis. Consectetur, sint aut! Dolorum, beatae? Odit alias dolorum reiciendis, eos accusamus suscipit reprehenderit quibusdam! Quidem officia dolore laboriosam?</p>
                        <div className='d-flex gap-3'>
                            <div className='rounded-3 border d-flex align-items-center gap-2 px-2'>
                                <FaMinus className='' />
                                <p className=''>number</p>
                                <FaPlus />
                            </div>

                            <Button
                                children='Add to Cart'
                                className='btn-primary text-white'
                            />
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, aspernatur. Non praesentium veritatis quis culpa quas ullam aut totam magnam.</p>
                    </div>

                </div>

                <div className="container mt-4">
                    <h3>Product Specifications</h3>
                    <table className="table table-striped">
                        <tbody>
                            {Object.entries(specifications).map(([key, value]) => (
                                <tr key={key}>
                                    <th>{key}</th>
                                    <td>{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="container mt-4">
                    <h3>Vendor</h3>
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>User 1</td>
                            </tr>

                            <tr>
                                <th>Email</th>
                                <td>email@email.com</td>
                            </tr>

                            <tr>
                                <th>Phone</th>
                                <td>+44 15465456456</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <ProductList sectionName='Related Products' />
            </div>
            <Footer />
        </div>
    )
}

export default ProductPage