import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer =  () => {
  return (
    <footer className="bg-dark text-white pt-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5>About Us</h5>
            <p>
              We are a company committed to providing the best service and quality products to our customers. Our team is dedicated to ensuring your satisfaction.
            </p>
          </div>
          <div className="col-md-4">
            <h5>Contact Us</h5>
            <ul className="list-unstyled">
              <li>Email: contact@company.com</li>
              <li>Phone: (123) 456-7890</li>
              <li>Address: 123 Main Street, Anytown, USA</li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5>Follow Us</h5>
            <ul className="list-unstyled d-flex">
              <li>
                <a href="#" className="text-white me-3">
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#" className="text-white me-3">
                  <i className="bi bi-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" className="text-white">
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center py-3">
          <p className="mb-0">© 2024 Your Company Name. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
