import React from 'react'
import "./Blog.scss"
import Button from '../button/ButtonCustom'

const Blog = () => {
  return (
    <section className='container py-5'>
        <div className="row ">
            <div className='d-flex flex-column justify-content-center align-items-center align-items-lg-start gap-3 col-12 col-lg-6 mb-3 mb-lg-0'>
                <h2>New Blog</h2>
                <p>Something Beautiful Belongs to Us</p>
                <p className='lead w-75'>
                    Something Beautiful Belongs to Us Michele seemed to say, was the 21st-century Gucci girl, an eccentric, fresh-faced weirdo who wasn’t afraid to wear backless fur-lined loafers, to personify the idea of ugly pretty.
                </p>
                <Button className='btn btn-primary text-white' children='Read More' />
            </div>

            <div className='col-12 col-lg-6'>
                <img 
                    src="https://www.bobswatches.com/images/hp-sell-luxury-watch-960.jpg" 
                    className='img-fluid rounded-5'
                />
            </div>
        </div>
    </section>
  )
}

export default Blog