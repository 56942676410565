import React, { useState } from 'react';
import "./NewsLetter.scss"

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Thank you for subscribing with ${email}!`);
    setEmail('');
  };

  return (
    <section className="container-fluid mt-5 py-5 bg-lightgray position-relative newsletter-section">
      <div className="row">
        <div className="col-md-6 col-lg-12">
          <div className="container p-4">
            <h3 className="text-center text-black mb-4">Subscribe to our Newsletter</h3>
            <form onSubmit={handleSubmit} className='d-flex align-items-center gap-4'>
              <div className="form-group w-75">
                <input 
                  type="email" 
                  className="form-control fs-5" 
                  id="email" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email" 
                  required 
                />
              </div>
              <button type="submit" className="btn btn-black w-25 fs-5">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
