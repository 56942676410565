import React, { useState } from "react";
import './AppHeader.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';


const AppHeader = () => {
    const [url, setUrl] = useState("home");

    const navigate = useNavigate();

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container >
                    <Navbar.Brand onClick={() => {
                        navigate('/')
                        setUrl('home')
                    }} className='text-black' href="/">
                        Client Logo
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link onClick={() => {
                                navigate('/')
                                setUrl('home')
                            }} className={`text-black ${url==="home"?'custom-active-nav':''}`}>Home</Nav.Link>
                            <Nav.Link onClick={() => {
                                navigate('/')
                                setUrl('home')
                            }} className='text-black'>Products</Nav.Link>
                            <Nav.Link onClick={() => {
                                navigate('/')
                                setUrl('home')
                            }} className='text-black'>Browse For Shop </Nav.Link>
                            <Nav.Link onClick={() => {
                                navigate('/')
                                setUrl('home')
                            }} className='text-black'>Browse For Categories</Nav.Link>
                            <Nav.Link onClick={() => {
                                navigate('/')
                                setUrl('home')
                            }} className='text-black'>Set Up My Business</Nav.Link>
                            <Nav.Link onClick={() => {
                                navigate('/')
                                setUrl('home')
                            }} className='text-black'>Contact Us</Nav.Link>


                        </Nav>
                        <div>
                            <button onClick={() => navigate('/login')} className='btn btn-primary  me-1'>Login</button>
                            <button onClick={() => navigate('/signup')} className='btn btn-primary'>Sign up</button>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default AppHeader