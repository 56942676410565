import React from 'react';
import "./ProductList.scss";
import ProductItem from '../product-item/ProductItem';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ProductList = ({ sectionName }) => {
  // product-detail
  const items = [
    {
      name: "Rolex",
      price: "Rs 250,000",
      image: "https://icasiostore.pk/wp-content/uploads/2024/04/A168WEHA-9A_front-600x600.webp"
    },
    {
      name: "Rolex",
      price: "Rs 250,000",
      image: "https://icasiostore.pk/wp-content/uploads/2024/04/A168WEHA-9A_front-600x600.webp"
    },
    {
      name: "Rolex",
      price: "Rs 250,000",
      image: "https://icasiostore.pk/wp-content/uploads/2024/04/A168WEHA-9A_front-600x600.webp"
    },
    {
      name: "Rolex",
      price: "Rs 250,000",
      image: "https://icasiostore.pk/wp-content/uploads/2024/04/A168WEHA-9A_front-600x600.webp"
    },
    {
      name: "Rolex",
      price: "Rs 250,000",
      image: "https://icasiostore.pk/wp-content/uploads/2024/04/A168WEHA-9A_front-600x600.webp",
    },
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    variableWidth: true,
    slidesToShow: 3, 
    slidesToScroll: 1,
    nextArrow: <FaArrowRight />,
    prevArrow: <FaArrowLeft />,
    responsive: [
      {
        breakpoint: 1024, // Large screens (lg)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768, // Medium screens (md)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480, // Small screens (sm)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <section className='container py-5'>
        <h2 className='text-center'>{sectionName}</h2>
        <div className=''>
            <Slider {...settings} >
                {items.map((item,index) => (
                  <ProductItem key={index} name={item.name} image={item.image} price={item.price} />
                ))}
            </Slider>
        </div>
        <div className='d-flex justify-content-center'>
          <button className='btn btn-outline-black mt-5 '>View More</button>
        </div>
    </section>
  )
}

export default ProductList